import { observable, action, reaction, computed, when, IReactionDisposer } from 'mobx'
import moment, { Moment } from 'moment'
import { asyncAction } from 'mobx-utils'
import { Subject, Subscription, timer } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { apiService } from '@/services/api-services'
import { MintingPhaseModel } from '@/models/minting-phase-model'
import { VaultHandler } from '@/blockchainHandler/vaultHandler'
import { checkOptInStatus } from '@/blockchainHandler/utils'
import { walletStore } from '@/stores/wallet-store'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { MINT_MEMBER_TYPE } from '@/constants'
import { appProvider } from '@/app-provider'
export class MintingViewModel {
  @observable loading = false
  @observable timeCounter: any = {}
  @observable countdownText = ''
  @observable countdownTextAction = ''
  @observable phases: MintingPhaseModel[] = []
  @observable openingPhase?: MintingPhaseModel = undefined
  @observable phaseWhitelistMinted = 0
  @observable phasePublicMinted = 0
  @observable pendingNFT = 0
  @observable freeNFT = 0
  @observable optInMintAppStatus = false
  @observable statusChecking = false
  @observable optInLoading = false
  @observable minting = false
  @observable claiming = false
  @observable memberType: 'whitelist' | 'public' = 'public'
  @observable nftMintedAmount = 0
  @observable maxMint = 0

  @observable openHistoryDialog = false
  @observable historyPage = 1

  _unsubscribe = new Subject()
  private _disposers: IReactionDisposer[] = []

  mockTime = new Date('Wednesday, May 25, 2022 2:45:40 PM GMT+07:00').getTime()

  vaultHandler?: VaultHandler

  constructor() {
    this.loadData()
    this._disposers = [
      reaction(
        () => walletStore.account,
        async (walletAddress) => {
          if (walletAddress) this.fetchMember(walletAddress)
        },
        {
          fireImmediately: true,
        }
      ),
    ]
  }

  destroy(): void {
    this._unsubscribe.next()
    this._unsubscribe.complete()
  }

  @asyncAction *fetchMember(walletAddress: string) {
    yield when(() => !!this.vaultHandler)
    console.log('=======walletAddress', walletAddress)
    this.checkOptInStatus()
    if (walletStore.account) {
      const memberType = yield this.vaultHandler?.getMemberType(walletAddress)
      if (memberType === MINT_MEMBER_TYPE.whitelist) {
        this.memberType = 'whitelist'
        this.maxMint = this.vaultHandler?.maxMintWhitelistWallet || 0
      } else {
        this.memberType = 'public'
        this.maxMint = this.vaultHandler?.maxMintPublicWallet || 0
      }
      this.nftMintedAmount = yield this.vaultHandler?.getNFTMintedAmount(walletAddress)
    }
  }

  @action fetchingCountdown() {
    timer(1000, 1000)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(() => {
        this.calculateCowndown()
      })
  }

  @action fetchingBoughtAmount() {
    timer(1000, 30000)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(async () => {
        await this.fetchBoughtAmount()
      })
  }

  @action setOpenHistoryDialog(value: boolean) {
    this.openHistoryDialog = value
  }

  @action onHistoryPageChange(page: number) {
    this.historyPage = page
  }

  @asyncAction *loadData() {
    this.vaultHandler = new VaultHandler()
    this.phases = yield apiService.mintingPhases.find({ _limit: 1 }) || []
    this.openingPhase = this.phases.find((item: MintingPhaseModel) => item.status === 'active')
    console.log('openingPhase', this.openingPhase)
    const isFinalize = moment().isSameOrAfter(this.mintingTimeLine?.publicTimeEnd)
    if (this.openingPhase) {
      this.calculateCowndown()
      this.fetchBoughtAmount()
      if (!isFinalize) {
        this.fetchingCountdown()
        this.fetchingBoughtAmount()
      }
    }
  }

  @asyncAction *checkOptInStatus() {
    try {
      if (this.vaultHandler) {
        this.statusChecking = true
        this.optInMintAppStatus = yield checkOptInStatus(walletStore.account, this.vaultHandler.mintAppID)
        this.pendingNFT = (yield this.vaultHandler.getPendingNFT(walletStore.account))?.index || 0
        this.freeNFT = yield this.vaultHandler.getFreeNFT(walletStore.account)
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.statusChecking = false
    }
  }

  @asyncAction *fetchBoughtAmount() {
    this.phasePublicMinted = yield apiService.mintTransaction.count({
      mintPhase: this.openingPhase?.distributeName,
      mintType: 'public',
    })
    this.phaseWhitelistMinted = yield apiService.mintTransaction.count({
      mintPhase: this.openingPhase?.distributeName,
      mintType: 'whitelist',
    })
  }

  @action calculateCowndown() {
    const currentTime = moment()
    const isWhitelistOptInStarted = currentTime.isAfter(this.whitelistOptInStart)
    const isWhitelistOptInEnded = currentTime.isAfter(this.whitelistOptInEnd)
    const isWhitelistStarted = currentTime.isAfter(this.whitelistTimeStart)
    const isWhitelistEnded = currentTime.isAfter(this.whitelistTimeEnd)
    const isPublicStarted = currentTime.isAfter(this.publicTimeStart)
    const isPublicEnded = currentTime.isAfter(this.publicTimeEnd)
    const isAirdropStarted = currentTime.isAfter(this.airdropTimeStart)
    const isAirdropEnded = currentTime.isAfter(this.airdropTimeEnd)
    let targetTime: Moment
    let mintStageName = ''
    if (!isWhitelistOptInStarted) {
      this.countdownText = `${this.phaseName} - Whitelist & Airdrop members Opt-in`
      this.countdownTextAction = 'Open in'
      targetTime = moment(this.whitelistOptInStart)
      mintStageName = 'Whitelist & Airdrop members Opt-in'
    } else if (!isWhitelistOptInEnded) {
      this.countdownText = `${this.phaseName} - Whitelist & Airdrop members Opt-in`
      this.countdownTextAction = 'Close in'
      targetTime = moment(this.whitelistOptInEnd)
      mintStageName = 'Whitelist & Airdrop members Opt-in'
    } else if (!isWhitelistStarted) {
      this.countdownText = `${this.phaseName} - Whitelist Mint`
      this.countdownTextAction = 'Open in'
      targetTime = moment(this.whitelistTimeStart)
      mintStageName = 'Whitelist Mint'
    } else if (!isWhitelistEnded) {
      this.countdownText = `${this.phaseName} - Whitelist Mint`
      this.countdownTextAction = 'Close in'
      targetTime = moment(this.whitelistTimeEnd)
      mintStageName = 'Whitelist Mint'
    } else if (!isPublicStarted) {
      this.countdownText = `${this.phaseName} - Public Mint`
      this.countdownTextAction = 'Open in'
      targetTime = moment(this.publicTimeStart)
      mintStageName = 'Public Mint'
    } else if (!isPublicEnded) {
      this.countdownText = `${this.phaseName} - Public Mint`
      this.countdownTextAction = 'Close in'
      targetTime = moment(this.publicTimeEnd)
      mintStageName = 'Public Mint'
    } else if (!isAirdropStarted) {
      this.countdownText = `${this.phaseName} - NFT Claim`
      this.countdownTextAction = 'Open in'
      targetTime = moment(this.airdropTimeStart)
      mintStageName = 'NFT Claim'
    } else if (!isAirdropEnded) {
      this.countdownText = `${this.phaseName} - NFT Claim`
      this.countdownTextAction = 'Close in'
      targetTime = moment(this.airdropTimeEnd)
      mintStageName = 'NFT Claim'
    } else {
      this.countdownText = 'Sale has finished'
      this.countdownTextAction = ''
      targetTime = moment('')
    }

    const now = Math.trunc(currentTime.valueOf() / 1000)
    const dateInSeconds = Math.trunc(targetTime.valueOf() / 1000)
    const countdownDays = Math.trunc((dateInSeconds - now) / 60 / 60 / 24)
    const countdownHours = Math.trunc((dateInSeconds - now) / 60 / 60) % 24
    const countdownMinutes = Math.trunc((dateInSeconds - now) / 60) % 60
    const countdownSeconds = (dateInSeconds - now) % 60

    this.timeCounter = {
      mintStageName,
      countdownDays,
      countdownHours,
      countdownMinutes,
      countdownSeconds,
      isWhitelistOptInStarted,
      isWhitelistOptInEnded,
      isWhitelistStarted,
      isWhitelistEnded,
      isPublicStarted,
      isPublicEnded,
      isAirdropStarted,
      isAirdropEnded,
    }
  }

  @asyncAction *optInMintApplication() {
    try {
      if (this.vaultHandler) {
        this.optInLoading = true
        yield this.vaultHandler?.sendOptInMintApplication(walletStore.account)
        this.fetchMember(walletStore.account)
        this.optInMintAppStatus = yield checkOptInStatus(walletStore.account, this.vaultHandler?.mintAppID)
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.optInLoading = false
    }
  }

  @asyncAction *mintNFT() {
    try {
      this.minting = true
      if (this.vaultHandler) {
        yield this.vaultHandler.mintNFT(walletStore.account)
        const pendingNFT = yield this.vaultHandler.getPendingNFT(walletStore.account)
        this.pendingNFT = pendingNFT
        if (this.pendingNFT) {
          const mintType = this.isPublicStarted ? 'public' : 'whitelist'
          yield apiService.noteNfts.createNFTMetadata({
            assetID: pendingNFT.index,
            mintType: mintType,
            walletAddress: walletStore.account,
            distribute: this.openingPhase?.distributeName || '',
            index: Number(pendingNFT.params['unit-name'].replace('ANN', '')),
          })
          snackController.success('You have minted NFT successfully')
        }
        yield this.fetchBoughtAmount()
        yield this.fetchMember(walletStore.account)
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.minting = false
    }
  }

  @asyncAction *claimNFT() {
    try {
      if (this.vaultHandler && this.pendingNFT) {
        this.claiming = true
        yield this.vaultHandler.claimNFT(walletStore.account, this.pendingNFT)
        this.pendingNFT = (yield this.vaultHandler.getPendingNFT(walletStore.account))?.index || 0
        yield this.fetchMember(walletStore.account)
        snackController.success('You have claimed NFT successfully')
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.claiming = false
    }
  }

  @asyncAction *claimFreeNFT() {
    try {
      if (this.vaultHandler && this.freeNFT) {
        this.claiming = true
        yield this.vaultHandler.claimNftFree(walletStore.account, this.freeNFT)
        this.freeNFT = yield this.vaultHandler.getFreeNFT(walletStore.account)
        yield this.fetchMember(walletStore.account)
        snackController.success('You have claimed NFT successfully')
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.claiming = false
    }
  }

  @computed get mintingTimeLine() {
    return this.openingPhase?.data?.mintingTimeLine
  }
  @computed get whitelistOptInStart() {
    return new Date(this.mintingTimeLine?.whitelistOptInStart || '')
  }
  @computed get whitelistOptInEnd() {
    return new Date(this.mintingTimeLine?.whitelistOptInEnd || '')
  }
  @computed get whitelistTimeStart() {
    return new Date(this.mintingTimeLine?.whitelistTimeStart || '')
  }
  @computed get whitelistTimeEnd() {
    return new Date(this.mintingTimeLine?.whitelistTimeEnd || '')
  }
  @computed get publicTimeStart() {
    return new Date(this.mintingTimeLine?.publicTimeStart || '')
  }
  @computed get publicTimeEnd() {
    return new Date(this.mintingTimeLine?.publicTimeEnd || '')
  }
  @computed get airdropTimeStart() {
    return new Date(this.mintingTimeLine?.airdropTimeStart || '')
  }
  @computed get airdropTimeEnd() {
    return new Date(this.mintingTimeLine?.airdropTimeEnd || '')
  }
  @computed get countdownDays() {
    return this.timeCounter.countdownDays || ''
  }
  @computed get countdownHours() {
    return this.timeCounter.countdownHours || ''
  }
  @computed get countdownMinutes() {
    return this.timeCounter.countdownMinutes || ''
  }
  @computed get countdownSeconds() {
    return this.timeCounter.countdownSeconds || ''
  }
  @computed get nftName() {
    return this.openingPhase?.nftName
  }
  @computed get phaseName() {
    return this.openingPhase?.name
  }
  @computed get maxAllocationWhitelist() {
    return this.openingPhase?.data?.maxAllocationWhitelist
  }
  @computed get maxAllocationPublic() {
    return this.openingPhase?.data?.maxAllocationPublic
  }
  @computed get whitelistDiscount() {
    return this.openingPhase?.data?.whitelistDiscount || ''
  }
  @computed get nftPrice() {
    return this.openingPhase?.fee
  }
  @computed get nftTotalAmount() {
    return this.openingPhase?.amount
  }
  @computed get whitelistAmount() {
    return this.openingPhase?.whitelistAmount
  }
  @computed get publicAmount() {
    return this.openingPhase?.publicAmount
  }
  @computed get isWhitelistOptInStarted() {
    return this.timeCounter?.isWhitelistOptInStarted
  }
  @computed get isWhitelistOptInEnded() {
    return this.timeCounter?.isWhitelistOptInEnded
  }
  @computed get isWhitelistStarted() {
    return this.timeCounter?.isWhitelistStarted
  }
  @computed get isWhitelistEnded() {
    return this.timeCounter?.isWhitelistEnded
  }
  @computed get isPublicStarted() {
    return this.timeCounter?.isPublicStarted
  }
  @computed get isPublicEnded() {
    return this.timeCounter?.isPublicEnded
  }
  @computed get isAirdropStarted() {
    return this.timeCounter?.isAirdropStarted
  }
  @computed get isAirdropEnded() {
    return this.timeCounter?.isAirdropEnded
  }

  @computed get whitelistOptInStatus() {
    if (!this.isWhitelistOptInStarted) return 'UPCOMING'
    if (!this.isWhitelistOptInEnded) return 'OPEN'
    if (this.isWhitelistOptInEnded) return 'ENDED'
    return ''
  }

  @computed get whitelistStatus() {
    if (!this.isWhitelistStarted) return 'UPCOMING'
    if (!this.isWhitelistEnded) return 'OPEN'
    if (this.isWhitelistEnded) return 'ENDED'
    return ''
  }
  @computed get publicStatus() {
    if (!this.isWhitelistEnded) return 'UPCOMING'
    if (!this.isPublicEnded) return 'OPEN'
    if (this.isPublicEnded) return 'ENDED'
    return ''
  }
  @computed get airdropStatus() {
    if (!this.isAirdropStarted) return 'UPCOMING'
    if (!this.isAirdropEnded) return 'OPEN'
    if (this.isAirdropEnded) return 'ENDED'
    return ''
  }

  @computed get currentRoadmapStep() {
    if (this.isAirdropEnded) return 5
    if (this.isAirdropStarted) return 4
    if (this.isPublicEnded) return 3
    if (this.isWhitelistEnded) return 3
    if (this.isWhitelistStarted) return 2
    if (this.isWhitelistOptInEnded) return 2
    if (this.isWhitelistOptInStarted) return 1
    return 0
  }

  @computed get roadmap() {
    return [
      {
        title: 'Whitelist & Airdrop members Opt-in',
        caption: 'Whitelist & Airdrop members should do opt-in at this time',
        when: `${moment(this.openingPhase?.data?.mintingTimeLine.whitelistOptInStart)
          .utc()
          .format('YYYY-MM-DD, HH:mm')} UTC ~ ${moment(this.openingPhase?.data?.mintingTimeLine.whitelistOptInEnd)
          .utc()
          .format('YYYY-MM-DD, HH:mm')} UTC `,
        status: this.whitelistOptInStatus,
        hideAmount: true,
      },
      {
        title: 'Whitelist Mint',
        caption: `Only Whitelist Members can mint`,
        nft: this.whitelistAmount,
        when: `${moment(this.openingPhase?.data?.mintingTimeLine.whitelistTimeStart)
          .utc()
          .format('YYYY-MM-DD, HH:mm')} UTC ~ ${moment(this.openingPhase?.data?.mintingTimeLine.whitelistTimeEnd)
          .utc()
          .format('YYYY-MM-DD, HH:mm')} UTC`,
        minted: `${this.phaseWhitelistMinted}/${this.whitelistAmount}`,
        status: this.whitelistStatus,
      },
      {
        title: 'Public Mint',
        caption: 'All users can mint',
        nft: this.publicAmount,
        when: `${moment(this.openingPhase?.data?.mintingTimeLine.publicTimeStart)
          .utc()
          .format('YYYY-MM-DD, HH:mm')} UTC ~ ${moment(this.openingPhase?.data?.mintingTimeLine.publicTimeEnd)
          .utc()
          .format('YYYY-MM-DD, HH:mm')} UTC`,
        minted: `${this.phasePublicMinted}/${this.publicAmount}`,
        status: this.publicStatus,
      },
      {
        title: 'NFT Claim',
        caption: 'Airdrop members can claim in this time.',
        caption2: 'Whitelist & Public mint members can claim until this time.',
        caption3: "If you don't make a claim until this time, you can't get NFT.",
        when: `${moment(this.openingPhase?.data?.mintingTimeLine.airdropTimeStart)
          .utc()
          .format('YYYY-MM-DD, HH:mm')} UTC ~ ${moment(this.openingPhase?.data?.mintingTimeLine.airdropTimeEnd)
          .utc()
          .format('YYYY-MM-DD, HH:mm')} UTC`,
        // minted: `${this.phasePublicMinted}/${this.publicAmount}`,
        status: this.airdropStatus,
        hideAmount: true,
      },
    ]
  }

  get description() {
    return [
      { label: 'Description', description: 'AlgoLaunch Genesis Mint sells 1,000 NFTs.' },
      { label: '', description: 'Only whitelist members can mint on the whitelist sale' },
      { label: '', description: 'And anyone can participate in minting at the public sale.' },
      { label: '', description: 'The quantity not sold in the whitelist sale can be minted in the public sale.' },
      // { label: 'When', description: '5/14/22 in four phases' },
      { label: 'Amount', description: '1,000 NFT for this sale phase' },
      { label: 'Price', description: `${this.nftPrice} $ALGO` },
      { label: 'Max', description: `1 per wallet` },
    ]
  }

  @computed get mintButtonEnable() {
    const currentTime = moment()
    const isInWhitelistMint = currentTime.isBetween(this.whitelistTimeStart, this.whitelistTimeEnd)
    const isInPublicMint = currentTime.isBetween(this.publicTimeStart, this.publicTimeEnd)
    const totalNFT = this.vaultHandler?.totalNFTSupply
    const totalNFTMinted = this.vaultHandler?.totalNFTMinted
    if (totalNFTMinted && totalNFT && totalNFTMinted >= totalNFT) {
      return false
    }
    if (isInWhitelistMint) {
      if (this.memberType === 'whitelist' && this.nftMintedAmount < this.maxMint) return true
      else return false
    } else if (isInPublicMint) {
      if (this.nftMintedAmount < this.maxMint) return true
      else return false
    } else {
      return false
    }
  }

  @computed get totalPhaseNFTMinted() {
    return this.phaseWhitelistMinted + this.phasePublicMinted
  }

  @computed get history() {
    // return [
    //   { name: 'Phase1', date: '2022-04-30', price: '110 LCH', quantity: '110 / 1212 LCH', status: 'close' },
    //   { name: 'Phase2', date: '2022-04-30', price: '110 LCH', quantity: '110 / 1212 LCH', status: 'open' },
    //   { name: 'Phase3', date: '2022-04-30', price: '110 LCH', quantity: '110 / 1212 LCH', status: 'upcoming' },
    //   { name: 'Phase4', date: '2022-04-30', price: '110 LCH', quantity: '110 / 1212 LCH', status: 'open' },
    //   { name: 'Phase5', date: '2022-04-30', price: '110 LCH', quantity: '110 / 1212 LCH', status: 'open' },
    //   { name: 'Phase6', date: '2022-04-30', price: '110 LCH', quantity: '110 / 1212 LCH', status: 'close' },
    //   { name: 'Phase7', date: '2022-04-30', price: '110 LCH', quantity: '110 / 1212 LCH', status: 'open' },
    //   { name: 'Phase8', date: '2022-04-30', price: '110 LCH', quantity: '110 / 1212 LCH', status: 'upcoming' },
    //   { name: 'Phase9', date: '2022-04-30', price: '110 LCH', quantity: '110 / 1212 LCH', status: 'open' },
    // ]
    return this.phases.map((item: MintingPhaseModel) => {
      return {
        name: item.name,
        date: moment(item.data?.mintingTimeLine.whitelistTimeStart).format('YYYY-MM-DD'),
        price: item.fee,
        quantity: item.amount,
        status:
          item.status === 'active'
            ? moment().isSameOrAfter(this.mintingTimeLine?.publicTimeEnd)
              ? 'close'
              : 'open'
            : item.status,
      }
    })
  }

  // @computed get numberItemDisplay() {
  //   return this.$vuetify.breakpoint.sm ? 3 : 5
  // }

  @computed get historyPageNumber() {
    return Math.ceil(this.history.length / 3)
  }

  @computed get pageNumber() {
    return Math.ceil(this.history.length / 3)
  }

  @computed get historyDisplay() {
    return this.history.slice((this.historyPage - 1) * 3, this.historyPage * 3)
  }

  @computed get whitelistOptInTime() {
    return `${moment(this.openingPhase?.data?.mintingTimeLine.whitelistOptInStart)
          .utc()
          .format('YYYY-MM-DD, HH:mm')} UTC ~ ${moment(this.openingPhase?.data?.mintingTimeLine.whitelistOptInEnd)
          .utc()
          .format('YYYY-MM-DD, HH:mm')} UTC `
  }
}
