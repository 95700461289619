import { AlgoClient } from '@/algo-client'
import { walletStore } from '@/stores/wallet-store'
import algosdk from 'algosdk'
import { NFT_MINTER_CONFIG } from './constants'
import { buildState, readGlobalState, readLocalState, signTransactions } from './utils'
import { get } from 'lodash'
import { ethers } from 'ethers'

export class VaultHandler {
  mintAppID = 0
  mintAppAccount? = ''
  globalState?: any
  feeTokenId = 0
  feeReceiver? = ''
  mintFee?: number
  maxMintWhitelistWallet = 1
  maxMintPublicWallet = 1
  totalNFTSupply?: number = 0
  totalNFTMinted?: number = 0

  constructor() {
    this.init()
  }

  async init() {
    const { VUE_APP_MINT_APP_ID, VUE_APP_MINT_APP_ACCOUNT, VUE_APP_FEE_RECEIVER } = process.env
    this.mintAppID = Number(VUE_APP_MINT_APP_ID) || 0
    this.mintAppAccount = VUE_APP_MINT_APP_ACCOUNT
    this.feeReceiver = VUE_APP_FEE_RECEIVER
    this.globalState = await this.getGlobalState()
    this.feeTokenId = get(this.globalState?.ASA_ID, 'value.uint')
    this.mintFee = get(this.globalState.NFT_FEE, 'value.uint')
    this.totalNFTSupply = get(this.globalState.NFT_TOTAL, 'value.uint')
    this.totalNFTMinted = get(this.globalState.NFT_MINTED, 'value.uint')
  }

  async getGlobalState() {
    this.globalState = await readGlobalState(AlgoClient, this.mintAppID)
    return buildState(this.globalState, NFT_MINTER_CONFIG.globals)
  }

  async getLocalState(account) {
    const res = await readLocalState(AlgoClient, account, this.mintAppID)
    console.log('========localState', buildState(res, NFT_MINTER_CONFIG.locals))
    return buildState(res, NFT_MINTER_CONFIG.locals)
  }

  async sendOptInMintApplication(walletAddress: string) {
    const params = await AlgoClient.getTransactionParams().do()
    if (params && this.mintAppID) {
      const txn = algosdk.makeApplicationOptInTxn(walletAddress, params, this.mintAppID)
      const signedTxn = await signTransactions(walletStore.selectedWallet, txn)
      const { txId } = await AlgoClient.sendRawTransaction(signedTxn).do()
      await algosdk.waitForConfirmation(AlgoClient, txId, 3)
    }
  }

  async mintNFT(walletAddress: string, amount = 1, mintFee = 'ALGO') {
    const params = await AlgoClient.getTransactionParams().do()
    if (params && this.mintFee && this.mintAppID && this.mintAppAccount && this.feeReceiver) {
      const tranferAlgoTxn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        suggestedParams: { ...params },
        from: walletAddress,
        to: this.mintAppAccount,
        amount: 100000 * amount, //0.1 ALGO * amount
      })

      const mintTxn = algosdk.makeApplicationNoOpTxnFromObject({
        suggestedParams: { ...params, fee: 1000 + amount * 1000, flatFee: true },
        from: walletAddress,
        appIndex: this.mintAppID,
        appArgs: [new Uint8Array(Buffer.from(NFT_MINTER_CONFIG.callApps.Mint)), algosdk.encodeUint64(amount)],
      })

      const tranferAssetsTxn =
        mintFee === 'ALGO'
          ? algosdk.makePaymentTxnWithSuggestedParamsFromObject({
              suggestedParams: { ...params },
              from: walletAddress,
              to: this.feeReceiver,
              amount: this.mintFee * amount,
            })
          : algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
              suggestedParams: { ...params },
              from: walletAddress,
              to: this.feeReceiver,
              amount: this.mintFee * amount,
              assetIndex: this.feeTokenId,
            })

      const signedTxns = await signTransactions(walletStore.selectedWallet, [tranferAlgoTxn, mintTxn, tranferAssetsTxn])
      const { txId } = await AlgoClient.sendRawTransaction(signedTxns).do()
      await algosdk.waitForConfirmation(AlgoClient, txId, 3)
    }
  }

  async getPendingNFT(walletAddress: string) {
    const localState = await this.getLocalState(walletAddress)
    const assetID = get(localState.NFT_PENDING_ID, 'value.uint')
    if (assetID) return await AlgoClient.getAssetByID(assetID).do()
  }

  async getFreeNFT(walletAddress: string) {
    const localState = await this.getLocalState(walletAddress)
    return get(localState.NFT_FREE, 'value.uint')
  }

  async getMemberType(walletAddress: string) {
    const localState = await this.getLocalState(walletAddress)
    return get(localState.MEMBER_TYPE, 'value.uint')
  }

  async getNFTMintedAmount(walletAddress: string) {
    const localState = await this.getLocalState(walletAddress)
    return get(localState.NFT_MINTED, 'value.uint')
  }

  async claimNFT(walletAddress: string, nftAssetID: number) {
    const params = await AlgoClient.getTransactionParams().do()
    if (params && this.mintAppID) {
      const optInASATxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: { ...params },
        from: walletAddress,
        to: walletAddress,
        amount: 0,
        assetIndex: nftAssetID,
      })

      const claimTxn = algosdk.makeApplicationNoOpTxnFromObject({
        suggestedParams: { ...params, fee: 2000, flatFee: true },
        from: walletAddress,
        appIndex: this.mintAppID,
        appArgs: [new Uint8Array(Buffer.from(NFT_MINTER_CONFIG.callApps.Claim)), algosdk.encodeUint64(0)],
        foreignAssets: [nftAssetID],
      })

      const signedTxns = await signTransactions(walletStore.selectedWallet, [optInASATxn, claimTxn])
      const { txId } = await AlgoClient.sendRawTransaction(signedTxns).do()
      await algosdk.waitForConfirmation(AlgoClient, txId, 3)
    }
  }

  async claimNftFree(walletAddress: string, nftAssetID: number) {
    const params = await AlgoClient.getTransactionParams().do()
    if (params && this.mintAppID && this.mintAppAccount) {
      const optInASATxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: { ...params },
        from: walletAddress,
        to: walletAddress,
        amount: 0,
        assetIndex: nftAssetID,
      })

      const claimTxn = algosdk.makeApplicationNoOpTxnFromObject({
        suggestedParams: { ...params, fee: 2000, flatFee: true },
        from: walletAddress,
        appIndex: this.mintAppID,
        appArgs: [new Uint8Array(Buffer.from(NFT_MINTER_CONFIG.callApps.ClaimNftFree))],
        foreignAssets: [nftAssetID],
        accounts: [this.mintAppAccount],
      })

      const signedTxns = await signTransactions(walletStore.selectedWallet, [optInASATxn, claimTxn])
      const { txId } = await AlgoClient.sendRawTransaction(signedTxns).do()
      await algosdk.waitForConfirmation(AlgoClient, txId, 3)
    }
  }
}
