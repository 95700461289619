





















import { Observer } from 'mobx-vue'
import { Component, Vue, Inject, Provide, Ref } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import { MintingViewModel } from '../viewmodels/minting-viewmodel'

@Observer
@Component({
  components: {
    'main-nft-infos': () => import('../components/main-nft-infos.vue'),
    'minting-count-down': () => import('../components/minting-count-down.vue'),
    'minting-roadmap': () => import('../components/minting-roadmap.vue'),
    'minting-description': () => import('../components/minting-description.vue'),
    'history-dialog': () => import('../dialogs/history-dialog.vue'),
  },
})
export default class NftMinting extends Vue {
  @Inject() providers
  walletStore = walletStore
  @Provide() vm = new MintingViewModel()
  tab = 0
  // @Ref('history-dialog') historyDialog: any

  destroyed() {
    this.vm.destroy()
  }
  // async openHistoryDialog() {
  //   await this.historyDialog.open()
  // }
}
